import React from "react"

const Trustpilot = () => {
  const ref = React.useRef(null)

  React.useEffect(() => {
    if (window["Trustpilot"]) {
      window["Trustpilot"].loadFromElement(ref.current, true)
    }
  }, [window["Trustpilot"]])

  const trustPilotConfig = {
    template_id: "53aa8912dec7e10d38f59f36",
    businessunit_id: "58485aa10000ff000598c414",
  }

  return (
    <div
      ref={ref}
      style={{ width: "100%" }}
      className="trustpilot-widget"
      data-locale={`en-US`}
      data-template-id={trustPilotConfig.template_id}
      data-businessunit-id={trustPilotConfig.businessunit_id}
      data-style-height="auto"
      data-style-width="100%"
      data-theme="light"
      data-stars="3,4,5"
    >
      <a
        href={`https://www.trustpilot.com/review/sixthreezero.com`}
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  )
}

export default Trustpilot
